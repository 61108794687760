<div class="container">
  <app-header title="Dashboard"></app-header>
  <div class="dashboard-wrapper">
    <!-- User img -->
    <div class="row mb-4 align-items-center">
      <div class="col-auto">
        <div class="user-img round me-2">
          <img appImg src="{{profile_img}}" />
        </div>
      </div>
      <div class="welcome-text ms-2 col">
        <h3>Welcome back <span class="green-txt">{{user.name}}</span></h3>
        <h4 class="align-content-center">Today is <span class="green-txt">{{today | date:'MMM dd, yyyy'}}</span>
          <svg-icon src="assets/img/ic_calander.svg"></svg-icon>
          <!-- and it is <span class="green-txt">78.F</span> -->
        </h4>
      </div>
      <div class="text-end col">
        <a class="btn btn-primary" routerLink="/doctors">Book an Appointment</a>
      </div>
    </div>
    <!-- Upcoming Appointment -->
    <div class="row mt-2 mb-4 upcoming-appointment">
      <div class="col">
        <div class="shadow-box">
          <h6 class="green-txt mb-4">Your upcoming appointment</h6>
          <div class="no-apointment" *ngIf="upcomingSlots.length==0">
            <p>There is no upcoming appointments for you.</p>
          </div>
          <div class="row align-items-center mb-2" *ngFor="let slot of upcomingSlots;">
            <div class="user-img me-2 col-auto">
              <div *ngIf="slot.doctor && (slot.doctor.profile_image!='' && slot.doctor.profile_image!=null)">
                <img class="radius-img" src="{{staticPath}}/{{slot.doctor.profile_image}}" />
              </div>
              <img class="radius-img" *ngIf="slot.doctor && (slot.doctor.profile_image==null || slot.doctor.profile_image=='')" src="assets/img/user-avatar.png" />
            </div>
            <div class="ms-2 col flex-column d-flex">
              <h6 class=" mb-3">{{slot.doctor.name}}
                <span *ngIf="slot.doctor.Doctor && slot.doctor.Doctor.specialization">with {{slot.doctor.Doctor.specialization}}</span>
              </h6>
              <small class="date mb-2">
                <svg-icon src="assets/img/ic_calander.svg"></svg-icon>{{slot.slot_start | date: 'dd MMMM, yyyy'}}
              </small>
              <small class="time">
                <svg-icon src="assets/img/clock.svg"></svg-icon>{{slot.slot_start | date: 'HH:mm aa'}}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Problem List -->
    <div class="row problem-list">
      <div class="col">
        <div class="shadow-box">
          <h6 class="mb-3">Your active problem list</h6>
          <div class="d-flex flex-column mb-4">
            <small *ngIf="problemList.length==0">
              <p>There is no problem exists.</p>
            </small>
            <small *ngFor="let problem of problemList;">
              <svg-icon src="assets/img/ic_sm_medications.svg"></svg-icon>
              {{problem}}
            </small>
          </div>
          <!-- <h6>Where do you want to go from here?</h6>
          <input class="form-control" id="" /> -->
        </div>
      </div>
    </div>

  </div>

</div>
