import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/config/app.constants';
import { RestApiService } from 'src/app/services/api.service';
import { AuthguardService } from 'src/app/services/authguard.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  filePath: string = '';
  profileForm: FormGroup;
  user: any;
  PatientDetail: any;
  constructor(public fb: FormBuilder, private apiServices: RestApiService, private toast: ToastrService, private router: Router, private authService: AuthguardService) {
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      email: [{value: '', disabled: true}, Validators.required],
      phone: [{value: '', disabled: true}, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      address: ['', Validators.required],
      zipcode: ['', Validators.required],
      password: ['', Validators.required],
      profile_image: [''],
      medical_condition: ['', Validators.required],
      medical_details: ['', Validators.required],
      dob: ['', Validators.required],
      exist_medical_condition: ['', Validators.required],
      allergies: ['', Validators.required],
      current_medicine: ['', Validators.required],
      family_history: ['', Validators.required],
      use_tobacco: [0, Validators.required],
      gender: ['', Validators.required],
      use_alcohal: [0, Validators.required],
    });
  }

  ngOnInit(): void {
    this.getProfile();
  }
  getProfile() {
    this.apiServices.getUserProfile().subscribe((profile: any) => {
      if(profile.success){
        const {PatientDetail, ...user} = profile.data;
        this.fillForm(user, PatientDetail);
      }
    });
  }
  fillForm(user, patient){
    this.filePath = user.profile_image ? `${AppConstants.staticPath}/${user.profile_image}` : null;
    this.profileForm.patchValue({
      name: user.name,
      email: user.email,
      phone: user.phone,
      address: user.address,
      zipcode: user.zipcode,
      dob: user.dob,
      profile_image: this.filePath,
      gender: user.gender,
      medical_condition: patient.medical_condition,
      medical_details: patient.medical_details,
      current_medicine: patient.current_medicine,
      use_tobacco: patient.use_tobacco,
      use_alcohal: patient.use_alcohal,
      allergies: patient.allergies,
      family_history: patient.family_history,
      exist_medical_condition: patient.exist_medical_condition,
    });
  }
  updateUser() {
    const reqBody = this.profileForm.value;
    let formData = new FormData();
    Object.keys(reqBody).forEach((x)=>{
      formData.append(x, reqBody[x]);
    });
    this.apiServices.updateUser(formData).subscribe((data: any) => {
      this.toast.success('Profile updated successfully');
      this.router.navigate(['/profile']);
    });
  }
  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    this.profileForm.patchValue({
      profile_image: file
    });
    this.profileForm.get('profile_image').updateValueAndValidity();

    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

}
