import { environment } from "src/environments/environment";

export class AppConstants {
  public static apiURL=environment.staticPath;
  public static staticPath=environment.staticPath;
   public static WEATHER_API_KEY = environment.WEATHER_API_KEY;
   public static USER_TYPES = {
    ADMIN: 1,
    DOCTOR: 2,
    PATIENT: 3,
  };
}
