import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { RestApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/Utils/helpers/common.service';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit {
  slots: any;
  selectedDate: any;
  selectedSlots: any;
  selectedSlot: any;
  doctorId: any;
  doctorDetail: any ={};
  paymentSuccess: any;
  available: boolean;
  loadedPaymentForm: boolean = false;
  @ViewChild('paymentform', { read: ViewContainerRef })
  private paymentformcontainerref: ViewContainerRef;
  constructor(private route: ActivatedRoute, private commonService: CommonService, private apiService: RestApiService, private toastr: ToastrService, private vcref: ViewContainerRef,
    private cfr: ComponentFactoryResolver, private router: Router) {
    this.doctorId = this.route.snapshot.paramMap.get('id');
    this.getDoctor(this.doctorId);
  }

  async ngOnInit() {
    this.selectedSlots = this.commonService.getTimeStops('11:00', '14:00');
    this.slots = this.commonService.getDatesArr(moment(), moment().add(13, 'd'));
    await this.getSlot(new Date());
    if(this.available) this.setActive(this.slots[0]);
  }
  getDoctor(id) {
    this.apiService.getDoctorProfile(id).subscribe((res: any)=> {
        if (res.data) this.doctorDetail = res.data;
    });
  }
  async loadPaymentComponent(reqBody){
    this.loadedPaymentForm = true;
    setTimeout(async()=>{
      this.vcref.clear();
      const { SlotPatmentComponent } = await import('src/app/MainScreens/slot-patment/slot-patment.component');
      let paymentComp = this.paymentformcontainerref.createComponent(
        this.cfr.resolveComponentFactory(SlotPatmentComponent)
      );
      paymentComp.instance.slotBody = reqBody;
      paymentComp.instance.sendMessageEvent.subscribe(data=>{
        console.log('data',data);
        this.paymentSuccess = data;
        // this.router.navigateByUrl("/home");
      })
    });
  }
  async getSlot(dt){
    const reqBody = {
      doctor_id: this.doctorId,
      date: dt
    }
    const res = await this.apiService.getSlots(reqBody).toPromise();
    this.available = !!res.success;
    if(!res.success) {
      this.toastr.warning(res.message);
    }
    const {data} = res;
    this.selectedSlots = data;
  }
  setActive(day) {
    this.getSlot(day);
    this.selectedDate = day;
    this.loadedPaymentForm = false;
  }
  setSlot(time) {
    this.selectedSlot = time;
  }
  async bookSlot() {
    if(!this.selectedDate || !this.selectedSlot){
      this.toastr.warning('All fields are mandatory');
      return false;
    }
    const reqBody = {
      doctor_id: this.doctorId,
      start: `${this.selectedDate} ${this.selectedSlot}`,
    };
    await this.loadPaymentComponent(reqBody);
  }
}
