<div class="container">
  <app-header title="Questions for the Current Visit"></app-header>
  <div class="visit-form-wrapper">
    <div class="shadow-box">
      <!-- <div class="d-flex mb-4">
        <button class="btn btn-sm btn-outline-primary" routerLink="/prior-visit">BACK</button>
      </div> -->
      <p class="green-txt pb-1 pt-0">Please list the main reason you are being seen by the doctor today?</p>
      <hr/>
      <div class="row">
        <div class="col left-fields">
          <div class="d-grid mb-2">
            <label class="form-label mb-1">Cardiovascular</label>
            <div class="d-flex">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="a" value="1">
                  Heart Racing
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="a" value="2" checked>
                  Chest Pain(Not appropriate for tele health go to ER)
                </label>
              </div>
            </div>
          </div>
          <hr/>

          <div class="d-grid mb-2">
            <label class="form-label mb-1">Gastroentrology</label>
            <div class="d-flex">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="b" value="1">
                  Nausea
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="b" id="Patient" value="2" checked>
                  Stomach Ache
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="b" value="3" checked>
                  Vomiting
                </label>
              </div>
            </div>
          </div>
          <hr/>

          <div class="d-grid mb-2">
            <label class="form-label mb-1">Pulmonary</label>
            <div class="d-flex">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="c" value="1">
                  Cough
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="c" value="2" checked>
                  COVID like symptoms
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="c" value="3" checked>
                  Asthma
                </label>
              </div>
            </div>
          </div>
          <hr/>

          <div class="d-grid mb-2">
            <label class="form-label mb-1">Neurology</label>
            <div class="d-flex">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="d" value="1">
                  Headache
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" name="d" value="2" checked>
                  Other symptoms
                </label>
              </div>
            </div>
          </div>
          <hr/>

          <div class="d-grid gap-2 col-6 mt-2 mb-4 pe-3">
            <button routerLink="/prior-visit" class="btn btn-primary btn-block">SAVE</button>
          </div>
        </div>
        <div class="col">
          <div class="d-grid mb-2">
            <label class="form-label mb-1">How long have you been experience these symptoms?</label>
            <textarea class="form-control" rows="3" placeholder="Type here..."></textarea>
          </div>
          <div class="d-grid mb-2">
            <label class="form-label mb-1">Did you take any medicines prior to today to address this issue?</label>
            <textarea class="form-control" rows="3" placeholder="Type here..."></textarea>
          </div>
          <div class="d-grid mb-2">
            <label class="form-label mb-1">What are you hoping to get out of today's visit with the doctor?</label>
            <textarea class="form-control" rows="3" placeholder="Type here..."></textarea>
          </div>
          <div class="d-grid mb-2">
            <label class="form-label mb-1">Did you see another doctor in this condition?</label>
            <textarea class="form-control" rows="3" placeholder="Type here..."></textarea>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
