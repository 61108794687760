import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/config/app.constants';
import { RestApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-doctors-list',
  templateUrl: './doctors-list.component.html',
  styleUrls: ['./doctors-list.component.scss']
})
export class DoctorsListComponent implements OnInit {
  doctorList:any = [];
  staticPath: string = AppConstants.staticPath;
  constructor(private apiServices: RestApiService) { }

  ngOnInit(): void {
    this.getDoctors();
  }
  getDoctors(){
    this.apiServices.getDoctors().subscribe((res: any)=>{
      this.doctorList = res.data ? res.data : [];
    });
  }

}
