import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {AuthguardService} from '../../../services/authguard.service';
import { NavigationService } from '../../../services/navigation.service';


const enum SideNavDirection {
  Left = 'left',
  Right = 'right'
};

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SideNavComponent implements OnInit {
  showSideNav: Observable<boolean>;

  @Input() sidenavTemplateRef: any;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: SideNavDirection = SideNavDirection.Left;

  isLoggedIn$: Observable<boolean>;
  constructor(private authService: AuthguardService, private navService: NavigationService, private Authguardservice: AuthguardService) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn();
    this.showSideNav = this.navService.getShowNav();
  }
  onSidebarClose() {
    this.navService.setShowNav(false);
  }
  getSideNavBarStyle(showNav: boolean) {
    let navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

    return navBarStyle;
  }
  logout() {
    this.Authguardservice.logoutUser();
  }

}
