import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss']
})
export class VisitFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  submit() {

  }

}
