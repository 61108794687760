import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  Stripe,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElement,
  StripeElements,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';
import { RestApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-slot-patment',
  templateUrl: './slot-patment.component.html',
  styleUrls: ['./slot-patment.component.scss']
})
export class SlotPatmentComponent implements OnInit {
  @Input() slotBody: any;
  @Output() sendMessageEvent = new EventEmitter();
  submitted:any;
  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;
  paymentForm: FormGroup;
  elements: StripeElements;
  card: StripeCardElement;
  paymentStatus: any;
  loading: any;

  constructor(private fb: FormBuilder, private stripeService: StripeService, private toastr: ToastrService, private apiService: RestApiService) {}

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.stripeService.elements(this.elementsOptions).subscribe(elements => {
      this.elements = elements;
      if (!this.card) {
        this.card = this.elements.create('card', this.cardOptions);
        this.card.mount('#card-element');
      }
    })
  }
  buy() {
    if(this.submitted) return false;
    this.submitted = true;
    this.loading = true;
    const name = this.paymentForm.get('name').value;
    const reqBody = {
      name,
      doctor_id: this.slotBody.doctor_id,
      start: this.slotBody.start,
    };
    this.stripeService.createToken(this.card, { name }).subscribe(result => {
      console.log('result', result);
      if(result.token) {
        reqBody['token'] = result.token.id;
        this.apiService.bookSlot(reqBody).subscribe((res: any)=>{
          if(res.success) {
            this.toastr.success(res.message);
            this.loading = false;
            this.submitted = false;
            this.paymentStatus = res['status'];
            this.sendMessageEvent.emit(res.data.receipt_url ? res.data.receipt_url : true);
          }else{
            this.toastr.error(res.message);
            this.loading = false;
            this.submitted = false;
            this.paymentStatus = res['status'];
            this.sendMessageEvent.emit(false);
          }
        });
      } else if(result.error) {
        this.paymentStatus = result.error.message;
        this.submitted = false;
      }
    })
  }

}
