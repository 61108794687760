<div class="container">
  <app-header title="Prior Visits"></app-header>
  <div class="visits-wrapper">
    <div class="row">
      <div class="col">
        <div class="shadow-box custom-table">
          <p class="table-header">Upcoming Call</p>
          <table class="table align-middle">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Intake Form</th>
                <th scope="Status">Status</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="upcomingSlots.length else noUpcomingSlots">
              <tr *ngFor="let slot of upcomingSlots; let i = index">
                <td>
                  <img *ngIf="slot.doctor && (slot.doctor.profile_image!='' && slot.doctor.profile_image!=null)" class="radius-img" src="{{staticPath}}/{{slot.doctor.profile_image}}" />
                  <img class="radius-img" *ngIf="slot.doctor && (slot.doctor.profile_image==null || slot.doctor.profile_image=='')" src="assets/img/user-avatar.png" />
                  <span>{{slot.doctor.name}}</span>
                </td>
                <td>{{slot.slot_start | date: 'dd MMM, yyyy'}}</td>
                <td>{{slot.slot_start | date: 'HH:mm aa'}}</td>
                <td>
                  <a class="btn btn-sm btn-outline-primary" [routerLink]="['edit', i]">View Form</a>
                </td>
                <td>
                  <button class="btn btn-success btn-sm" (click)="joinRoom(slot)">JOIN</button>
                </td>
              </tr>
              </ng-container>
              <ng-template #noUpcomingSlots>
                <tr>
                  <td colspan="5" class="text-center no-slot bg-light">No upcoming Slots found.</td>
                </tr>
              </ng-template>

            </tbody>
          </table>
        </div>
      </div>
      <div class="col">
        <div class="shadow-box custom-table">
          <p class="table-header">Past Call</p>
          <table class="table align-middle">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Intake Form</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="pastSlots.length else noPastSlots">
              <tr *ngFor="let slot of pastSlots; let i2 = index">
                <td>
                  <img *ngIf="slot.doctor && (slot.doctor.profile_image!='' && slot.doctor.profile_image!=null)" class="radius-img" src="{{staticPath}}/{{slot.doctor.profile_image}}" />
                  <img class="radius-img" *ngIf="slot.doctor && (slot.doctor.profile_image==null || slot.doctor.profile_image=='')" src="assets/img/user-avatar.png" />
                  <span>{{slot.doctor.name}}</span>
                </td>
                <td>{{slot.slot_start | date: 'dd MMM, yyyy'}}</td>
                <td>{{slot.slot_start | date: 'HH:mm aa'}}</td>
                <td>
                  <a class="btn btn-sm btn-outline-primary" (click)="openModal(i2)">View Notes</a>
                </td>
              </tr>
              </ng-container>
              <ng-template #noPastSlots>
                <tr>
                  <td colspan="5" class="text-center no-slot bg-light">No past Slots found.</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>

<app-notes-modal [show]="showModal" (close)="showModal=false" title="Notes">
  <div>Patient Notes Details</div>
</app-notes-modal>
