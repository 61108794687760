<div class="d-flex flex-row justify-content-center align-items-center h-100-vh">
  <section class="auth-box">
    <div class="row">
      <div
        class="col-md-6 auth-img-section text-center justify-content-center align-items-center flex-wrap d-flex  flex-column"
        style="background-image: url('assets/img/login_bg.png');">
        <div class="auth-logo">
          <img src="assets/img/logo.svg" />
        </div>
        <h2>WELCOME</h2>
        <p>Sign up to get full access</p>
      </div>
      <div class="col-md-6 px-5 py-4" *ngIf="!otpVerification">
        <div class="clearfix">
          <h5 class=" float-start">SIGN UP</h5>
          <small class="label float-end link" *ngIf="signupStep>1" (click)="changeStep(signupSteps, true)">BACK</small>
        </div>

        <div [ngSwitch]="signupStep">
          <form [formGroup]="signupForm">
            <section class="step-1" *ngSwitchCase="1">
              <div class="d-grid mb-2">
                <label class="mb-2">Name</label>
                <input type="text" class="form-control" placeholder="Please enter full name" name="name" formControlName="name" />
              </div>
              <div class="d-grid mb-2">
                <label class="mb-2">Email</label>
                <input type="email" class="form-control" formControlName="email" placeholder="Please enter email" name="email" />
              </div>
              <div class="d-grid mb-2">
                <label class="mb-2">Phone Number</label>
                <input type="text" maxlength="10" class="form-control" formControlName="phone" placeholder="Please enter phone number" name="phone" (keydown)="commonService.validateNumber($event)" />
              </div>
              <div class="row">
                <div class="d-grid mb-2 col">
                  <label class="mb-2">Address</label>
                  <input type="text" class="form-control" formControlName="address" placeholder="Please enter address" name="address" />
                </div>
                <div class="d-grid mb-2 col">
                  <label class="mb-2">Zipcode</label>
                  <input type="text" class="form-control" formControlName="zipcode" placeholder="Please enter zipcode" name="zipcode" (keydown)="commonService.validateNumber($event)" />
                </div>
              </div>
              <div class="d-grid mb-3">
                <label class="mb-2">Password</label>
                <input type="password" class="form-control" formControlName="password" placeholder="Please enter password" name="password" autocomplete="new-password" />
              </div>
              <div class="d-flex login-options">
                <label class="mb-2 me-4 form-label">Gender</label>
                <div class="form-check me-4">
                  <input class="form-check-input" type="radio" formControlName="gender" id="gender_male" value="M">
                  <label class="form-check-label" for="gender_male">
                    Male
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="gender" id="gender_female" value="F">
                  <label class="form-check-label" for="gender_female">
                    Female
                  </label>
                </div>
              </div>
              <div class="form-errors mb-1">
                <small class="text-danger" *ngIf="signupForm.get('email').errors?.pattern">Email address is invalid.</small>
                <small class="text-danger" *ngIf="signupForm.get('password').errors?.minlength">Password must be minimum 8 character long.</small>
              </div>
            </section>

            <section class="step-2" *ngSwitchCase="2">
              <h6>Other Informations</h6>
              <div class="mb-2">
                <label for="patientFile" class="form-label">Profile picture</label>
                <input class="form-control browse-file" type="file" (change)="onFileChange($event)">
              </div>
              <div class="d-grid mb-2">
                <label for="medical_condition" class="form-label">Medical Conditions</label>
                <textarea class="form-control" formControlName="medical_condition" id="medical_condition" rows="3"
                  placeholder="Enter your medical conditions"></textarea>
              </div>
              <div class="d-grid mb-2">
                <label class="mb-2 form-label" for="medical_details">Medical Details</label>
                <textarea class="form-control" formControlName="medical_details" id="medical_details" rows="3"
                  placeholder="Enter your medical detail"></textarea>
              </div>
              <div class="d-grid mb-4">
                <label class="mb-2">DOB</label>
                <input type="date" formControlName="dob" class="form-control" placeholder="Enter your date of birth" name="name" />
              </div>
            </section>

            <section class="step-3" *ngSwitchCase="3">
              <h6>Past Medical History</h6>
              <div class="mb-2">
                <label class="form-label">Please list your existing medical conditions?</label>
                <textarea class="form-control" formControlName="exist_medical_condition" rows="3" placeholder="Type here..."></textarea>
              </div>
              <div class="d-grid mb-2">
                <label class="form-label">What medicine are you currently on?</label>
                <textarea class="form-control" formControlName="current_medicine" rows="3" placeholder="Type here..."></textarea>
              </div>
              <div class="d-grid mb-4">
                <label class="mb-2 form-label">What are your allergies?</label>
                <textarea class="form-control" formControlName="allergies" rows="3" placeholder="Type here..."></textarea>
              </div>
            </section>

            <section class="step-4" *ngSwitchCase="4">
              <h6>Past Medical History</h6>
              <div class="mb-2">
                <label class="form-label">What is your family history?</label>
                <textarea class="form-control" rows="3" formControlName="family_history" placeholder="Type here..."></textarea>
              </div>

              <div class="d-grid login-options mb-2">
                <label class="form-label">Do you smoke or use tobacco?</label>
                <div class="form-check me-5">
                  <input class="form-check-input" type="radio" formControlName="use_tobacco" id="use_tobacco_yes" value="1">
                  <label class="form-check-label" for="use_tobacco_yes">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="use_tobacco" id="use_tobacco_no" value="0">
                  <label class="form-check-label" for="use_tobacco_no">
                    No
                  </label>
                </div>
              </div>

              <div class="d-grid login-options mb-2">
                <label class="mb-2 form-label">Do you consume alcohol?</label>
                <div class="form-check me-5">
                  <input class="form-check-input" type="radio" formControlName="use_alcohal" id="use_alcohal_yes" value="1">
                  <label class="form-check-label" for="use_alcohal_yes">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="use_alcohal" id="use_alcohal_no" value="0">
                  <label class="form-check-label" for="use_alcohal_no">
                    No
                  </label>
                </div>
              </div>
            </section>
          </form>
        </div>
        <div class="mb-2" *ngIf="!signupForm.valid && signupStep==4">
          <small class="mb-2 text-danger btn-block">Please fill all fields to proceed.</small>
        </div>
        <div class="d-grid mb-2 mt-2">
          <button [disabled]="signupForm.get('password').errors?.minlength || signupForm.get('email').errors?.pattern" (click)="changeStep(signupStep)" *ngIf="signupStep<4" class="btn btn-primary btn-block">NEXT</button>
          <button (click)="signupSubmit()" *ngIf="signupStep==4" class="btn btn-primary btn-block"  [disabled]="signupForm.invalid || processing">SUBMIT</button>
          <span class="green-txt mt-2" *ngIf="processing">Please wait. Its processing...</span>
        </div>
        <div class="d-flex justify-content-center">
          <a routerLink="/login" class="label mt-2">Already Users? Sign In</a>
        </div>
      </div>

      <!-- Otp Verify Section start -->

      <div class="col-md-6 px-5 py-4" *ngIf="otpVerification">
        <div class="clearfix">
          <h5 class=" float-start">OTP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
        </div>
        <div class="d-grid mb-3">
          <label class="form-label mb-4">Enter the 6 digit code send to +1 {{signupForm.value.phone}}</label>
          <ngx-otp-input
          #ngxotp
          [config]="otpInputConfig"
          (fill)="activateVerify($event)"
          (otpChange)="otpChange($event)"></ngx-otp-input>
        </div>
        <div class="mb-4">
          <div *ngIf="otpSent">
            <div class="mb-2">
              <small>Wait for 2 minutes to resend otp</small>
            </div>
            <div class="loading-dots">
              <span class="dot one">.</span>
              <span class="dot two">.</span>
              <span class="dot three">.</span>
            </div>
          </div>
          <small class="link" (click)="sendOtp()" *ngIf="!otpSent">Send otp again</small>
        </div>

        <div class="d-grid mb-2">
          <button (click)="verifyOtpHandler()" class="btn btn-primary btn-block" [disabled]="!activeVerify">CONTINUE</button>
        </div>
      </div>

    </div>
  </section>
</div>
