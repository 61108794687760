import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/config/app.constants';
import { AuthguardService } from 'src/app/services/authguard.service';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {
  activeTab : any = 1;
  user: any = {};
  profile_img: string;
  constructor(private authService: AuthguardService) { }

  ngOnInit(): void {
    this.authService.getUser().then((res)=>{
      this.user = res;
      if(res.profile_image) this.profile_img = `${AppConstants.staticPath}/${res.profile_image}`;
    });
  }

}
