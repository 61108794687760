import { Component, OnInit, ViewChild } from '@angular/core';
import { TwilioService } from 'src/app/services/twilio.service';
import { createLocalAudioTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant, createLocalVideoTrack } from 'twilio-video';
import { CameraComponent } from 'src/app/components/MeetingComponents/camera/camera.component';
import { SettingsComponent } from 'src/app/components/MeetingComponents/settings/settings.component';
import { ParticipantsComponent } from 'src/app/components/MeetingComponents/participants/participants.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-meeting-room',
  templateUrl: './meeting-room.component.html',
  styleUrls: ['./meeting-room.component.scss']
})
export class MeetingRoomComponent implements OnInit {
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('settings') settings: SettingsComponent;
  @ViewChild('participants') participants: ParticipantsComponent;
  activeRoom: Room;
  appointmentId: number;
  submitJoin: boolean = false;
  appointmentDetail: any;
  timeToJoin:boolean;

  constructor(private twilioService: TwilioService, private route: ActivatedRoute, private location: Location, private router: Router) {
    this.appointmentId = Number(this.route.snapshot.paramMap.get('slotid'));
  }

  ngOnInit(): void {
    const state:any = this.location.getState();
    if(!state.room) this.router.navigate(['prior-visit']);
    this.appointmentDetail = state.room;
    this.isRoomExist();
    this.setTriggerToActive();
  }
  setTriggerToActive() {
    const room = this.appointmentDetail;
    if(!room) return false;
    const roomTime = moment(room.slot_start).utc().format("YYYY-MM-DD HH:mm:ss");
    const diffTime = moment(roomTime).diff(moment().subtract(1, 'second'), 'seconds');
    setTimeout(()=>{
      this.canJoin();
    }, diffTime*1000);
  }
  canJoin() {
    const room = this.appointmentDetail;
    if(!room) this.timeToJoin = false;
    const roomTime = moment(room.slot_start).utc().format("YYYY-MM-DD HH:mm:ss");
    if( moment(roomTime).isSameOrBefore(moment()) ) {
      this.timeToJoin = true;
      return false;
    }
    this.timeToJoin = false;
  }
  async isRoomExist() {
    const rooms = await this.twilioService.getAllRooms();
    if(rooms.length){
      this.joinRoom(rooms[0].name);
    }
    console.log('ngOnInit', rooms);
  }


  async onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
    await this.camera.initializePreview(deviceInfo.deviceId);
    if (this.settings.isPreviewing) {
      const track = await this.settings.showPreviewCamera();
      if (this.activeRoom) {
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach(publication => publication.unpublish());
        await localParticipant.publishTrack(track);
      }
    }
  }
  ngOnDestroy() {
    console.log('ngOnDestroy');
  }

  async onLeaveRoom(_: boolean) {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
    }

    const videoDevice = this.settings.hidePreviewCamera();
    await this.camera.initializePreview(videoDevice && videoDevice.deviceId);

    this.participants.clear();
  }

  async joinRoom(roomName='testing') {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
    this.submitJoin = true;
    this.camera.finalizePreview();

    const tracks = await Promise.all([
      createLocalAudioTrack(),
      this.settings.showPreviewCamera()
    ]);

    this.activeRoom =
      await this.twilioService
        .joinOrCreateRoom(roomName, tracks);
    console.log('this.activeRoom', this.activeRoom);
    this.participants.initialize(this.activeRoom.participants);
    this.registerRoomEvents();
    this.submitJoin = false;
  }

  onParticipantsChanged(_: boolean) {
    this.twilioService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected',
        (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
      .on('participantConnected',
        (participant: RemoteParticipant) => this.participants.add(participant))
      .on('participantDisconnected',
        (participant: RemoteParticipant) => this.participants.remove(participant))
        .on('dominantSpeakerChanged',
          (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
        || (track as LocalVideoTrack).detach !== undefined);
  }
}
