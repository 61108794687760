<div class="container">
  <app-header title="Profile"></app-header>
  <div class="profile-wrapper">
    <!-- User img -->
    <div class="mb-4 align-items-center">
      <div class="shadow-box">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="user-img col-auto pe-1">
                <img class="radius-img" appImg src="{{profile_img}}" />
              </div>
              <div class="user-details col">
                <h6>{{user.name}}</h6>
                <div class="single-detail">
                  <small><b>DOB</b>{{user.dob | date:'MMM dd, yyyy'}}</small>
                </div>
                <div class="single-detail">
                  <small><b>Age</b>{{user.age}} {{user.gender}}</small>
                </div>
                <div class="single-detail">
                  <small><b>Patient ID</b>WXA{{user.id}}</small>
                </div>
                <button routerLink="/edit-profile" class="btn btn-primary btn-sm round-btn mt-2">Edit Profile</button>
              </div>
            </div>
          </div>
          <div class="user-upcomings col">
            <p class="green-txt mb-1">Your Upcoming Appointment</p>
            <small *ngFor="let slot of upcomingSlots;">
              <svg-icon src="assets/img/ic_calander.svg"></svg-icon>
              {{slot.slot_start | date: 'dd MMMM, yyyy'}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- Problem List -->
    <div class="row mt-2 mb-4 upcoming-appointment">
      <div class="col">
        <div class="shadow-box">
          <div class="single-list">
            <h6 class="mb-3">Your Active Problem List</h6>
            <div class="d-flex flex-column mb-4">
                <small *ngIf="problemList.length==0">
                  <p>There is no problem exists.</p>
                </small>
                <small *ngFor="let problem of problemList;">
                  <svg-icon src="assets/img/ic_sm_medications.svg"></svg-icon>
                  {{problem}}
                </small>
            </div>
          </div>
          <div class="single-list">
            <h6 class="mb-3">Past Medical History</h6>
            <div class="d-flex flex-column mb-4">
              <small *ngIf="medicalHistory.length==0">
                <p>There is no medical history exists.</p>
              </small>
              <small *ngFor="let problem of medicalHistory;">
                <svg-icon src="assets/img/ic_past_medical_history.svg"></svg-icon>
                {{problem}}
              </small>
            </div>
          </div>
          <div class="single-list">
            <h6 class="mb-3">Social History</h6>
            <div class="d-flex flex-column mb-4">
              <small *ngIf="patientDetails.use_alcohal=='1'">
                <svg-icon src="assets/img/ic_social_history.svg"></svg-icon>
                Drink Alcohol</small>
                <small *ngIf="patientDetails.use_tobacco=='1'">
                  <svg-icon src="assets/img/ic_social_history.svg"></svg-icon>
                  Smoke Tobacco</small>
            </div>
          </div>
          <div class="single-list">
            <h6 class="mb-3">Medications</h6>
            <div class="d-flex flex-column mb-4">
              <small *ngIf="medications.length==0">
                <p>There is no medications exists.</p>
              </small>
              <small *ngFor="let problem of medications;">
                <svg-icon src="assets/img/ic_sm_medications.svg"></svg-icon>
                {{problem}}
              </small>
            </div>
          </div>
          <div class="single-list">
            <h6 class="mb-3">Alergy</h6>
            <div class="d-flex flex-column mb-4">
              <small *ngIf="alergies.length==0">
                <p>There is no alergies exists.</p>
              </small>
              <small *ngFor="let problem of alergies;">
                <svg-icon src="assets/img/ic_allergy.svg"></svg-icon>
                {{problem}}
              </small>
            </div>
          </div>
        </div>
      </div>

<!-- My Feed -->
      <div class="col">
        <div class="shadow-box">
          <p class="green-txt">My Feed</p>
          <div class="single-list">
            <p>My Care Plan</p>
            <li>For high blood pressure take lisinopril. Your goal blood pressure is 140/70. Your exercise goal is 30 minutes per day.</li>
          </div>
          <div class="single-list">
            <p>My Medical Record</p>
            <li>Visit with Dr. Singh on 08/07/2015</li>
            <li>Visit with Dr. Vinay on 08/07/2015</li>
          </div>
        </div>
      </div>

    </div>
    <!-- Problem List -->
    <div class="row problem-list">
      <div class="col">
      </div>
    </div>

  </div>

</div>
