<div class="d-grid page-header mb-4">
  <h2 class="mb-2">{{title}}</h2>
  <p *ngIf="subTitle">{{subTitle}}</p>
  <div class="back-btn" *ngIf="back">
    <a (click)="goBack()" class="btn btn-primary btn-sm">
      <svg-icon src="assets/img/ic_arrow_left.svg"></svg-icon>
      Go back
    </a>
  </div>
</div>
<div class="d-flex"></div>
