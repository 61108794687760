
<div class="container">
  <app-header title="Preview"></app-header>
  <div class="row video-content">
    <div class="col-12">
      <div class="main-camera-preview">
        <app-camera #camera type="main" [style.display]="!!activeRoom ? 'none' : 'block'"></app-camera>
        <app-participants #participants (leaveRoom)="onLeaveRoom($event)"
          (participantsChanged)="onParticipantsChanged($event)" [style.display]="!!activeRoom ? 'block' : 'none'"
          [activeRoomName]="!!activeRoom ? activeRoom.name : null"></app-participants>
      </div>
      <div class="bottom-settings px-4 justify-content-center">
        <app-settings #settings [activeRoom]="activeRoom" (settingsChanged)="onSettingsChanged($event)"></app-settings>
        <ng-container *ngIf="!activeRoom; else activeRoomBtns">
        <button class="btn btn-primary ms-2" [disabled]="submitJoin || !timeToJoin" (click)="joinRoom()">
          <span *ngIf="submitJoin else clickJoin">Joining...</span>
          <ng-template #clickJoin>Click here to Join</ng-template>
        </button>
        </ng-container>
        <ng-template #activeRoomBtns>
          <button class="btn btn-danger ms-4" (click)="onLeaveRoom()">End Call</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
