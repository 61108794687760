import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppConstants } from '../config/app.constants';
import { User } from '../Utils/Interfaces/User';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthguardService } from './authguard.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  apiUrl = AppConstants.apiURL;
  constructor(private http: HttpClient, private toast: ToastrService, private authService: AuthguardService) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  loginUser(reqbody):Observable<User> {
    return this.http.post<User>(this.apiUrl+'/auth/login', JSON.stringify(reqbody), this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createUser(reqbody):Observable<User> {
    return this.http.post<User>(this.apiUrl+'/auth/signup', reqbody).pipe(
      catchError(this.handleError)
    );
  }
  updateUser(reqbody):Observable<User> {
    return this.http.post<User>(this.apiUrl+'/update-profile', reqbody).pipe(
      catchError(this.handleError)
    );
  }
  verifyOtp(reqbody):Observable<any> {
    return this.http.post<any>(this.apiUrl+'/auth/otp-verify', JSON.stringify(reqbody), this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getUserProfile():Observable<User> {
    return this.http.get<User>(this.apiUrl+'/get-profile', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getDoctors():Observable<User> {
    return this.http.get<User>(this.apiUrl+'/get-doctors', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getSlots(reqBody):Observable<any> {
    return this.http.post<any>(this.apiUrl+'/customer/get-slots', reqBody, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // getSlotDetails(id):Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/get-slot-details/${id}`).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }
  bookSlot(reqBody):Observable<User> {
    return this.http.post<User>(this.apiUrl+'/customer/book-slots', reqBody, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getBookings():Observable<User> {
    return this.http.get<User>(this.apiUrl+'/customer/get-upcoming-bookings', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getPastBooking():Observable<User> {
    return this.http.get<User>(this.apiUrl+'/customer/get-past-bookings', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getDoctorProfile(id):Observable<User> {
    return this.http.get<User>(this.apiUrl+'/get-doctor/'+id, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createRoom(reqBody):Observable<any> {
    return this.http.post<any>(this.apiUrl+'/create-room', reqBody, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getTwilioToken():Observable<any> {
    return this.http.get<any>(this.apiUrl+'/twilio-token', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getRoomsList():Observable<any> {
    return this.http.get<any>(this.apiUrl+'/rooms-list', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  handleError=(error)=> {
    let errorMessage = '';
    if(error.error && error.error.message) {
      // Get client-side error
    if([401,406].includes(error.error.statusCode)){
      this.authService.logoutUser();
    }
      errorMessage = error.error.message;
    } else if(error.status===0) {
      errorMessage = 'Something went wrong';
      this.authService.logoutUser();
    }else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toast.error(errorMessage);
    return throwError(errorMessage);
 }
}
