<div class="container">
  <div *ngIf="!paymentSuccess; else thankyou">
    <app-header
      title="Book an Appointment for {{doctorDetail.name}}"
      subTitle="Choose date and time to book your appointment slot."
      [back]="true"
    ></app-header>
    <div class="appointment-wrapper">
      <div class="row">
        <div class="col">
          <div class="row gx-2">
            <div class="col-6 mb-2" *ngFor="let day of slots;let i = index">
              <div class="shadow-box sm-radius date-box" (click)="available && setActive(day)"
                [ngClass]="{'active': selectedDate==day}">
                {{day | date: 'dd MMM, yyyy'}}
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <ng-container *ngIf="available">
            <div class="mb-2">
              <b *ngIf="doctorDetail.Doctor && doctorDetail.Doctor.consult_fee">Consultation Fee:
                {{doctorDetail.Doctor.consult_fee}} USD</b>
            </div>
            <div *ngIf="!loadedPaymentForm">
              <h3 class="bordered-title mb-4">Available slots for {{selectedDate | date: 'dd MMM, yyyy'}}</h3>
              <div class="no-slots py-4" *ngIf="!this.selectedSlots.length; else existSlots">
                <p>Sorry, there is no slots for selected date</p>
              </div>
              <ng-template #existSlots>
                <div class="time-slots row gx-2">
                  <div class="mb-2 col-3" *ngFor="let time of selectedSlots">
                    <div class="single-slot text-center p-2 px-5 link" (click)="setSlot(time)"
                      [ngClass]="{'active': selectedSlot==time}">
                      {{time}}
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary mt-4" (click)="bookSlot()">Continue for payment</button>
              </ng-template>
            </div>
            <ng-template #paymentform *ngIf="loadedPaymentForm"></ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #thankyou>
    <div class="text-center d-flex flex-column justify-content-center align-items-center" id="paymentThanks">
      <img src="assets/img/thank-you.png" />
      <h4 class="mb-4 mt-4">For book an appointment</h4>
      <a href="{{paymentSuccess}}" class="btn btn-primary" target="_blank">Click here to see reciept</a>
      <a routerLink="/home" class="mt-4">Click here to go to dashboard</a>
    </div>
  </ng-template>
</div>

