import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'src/app/config/app.constants';
import { RestApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prior-visit',
  templateUrl: './prior-visit.component.html',
  styleUrls: ['./prior-visit.component.scss']
})
export class PriorVisitComponent implements OnInit {

  modalContent = '';
  showModal: boolean = false;
  upcomingSlots: any = [];
  pastSlots: any = [];
  staticPath: string = AppConstants.staticPath;

  constructor(private apiService: RestApiService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getBookings();
    this.getPastBooking();
  }
  openModal(index) {
    this.showModal = true;
    this.modalContent = '';
  }
  getBookings() {
    this.apiService.getBookings().subscribe((res: any)=> {
        const {data} = res;
        this.upcomingSlots = data;
    });
  }
  getPastBooking() {
    this.apiService.getPastBooking().subscribe((res: any)=> {
        const {data} = res;
        this.pastSlots = data;
    });
  }
  joinRoom(room) {
    const roomTime = moment(room.slot_start).format("YYYY-MM-DD HH:mm:ss");
    if( moment(roomTime).isSameOrAfter(moment()) ) {
      this.toastr.warning("Please wait for meeting time");
      return false;
    }
    this.router.navigateByUrl('/meeting-room/'+room.id, { state: { room } });
  }
}
