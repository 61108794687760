
<div class="d-flex flex-row justify-content-center align-items-center h-100-vh">
  <section class="auth-box">
    <div class="row">
      <div class="col-md-6 auth-img-section text-center justify-content-center align-items-center flex-wrap d-flex  flex-column" style="background-image: url('assets/img/login_bg.png');">
        <div class="auth-logo">
          <img src="assets/img/logo.svg" />
        </div>
        <h2>WELCOME</h2>
        <p>Sign in to get full access</p>
      </div>
      <div class="col-md-6 p-5">
        <h5>Forgot Password</h5>
        <form [formGroup]="forgotForm" (ngSubmit)="forgotSubmit()">
          <div class="d-grid">
            <label class="mb-2">Email</label>
            <input type="email" formControlName="username" class="form-control" placeholder="Please enter email" name="username" />
          </div>
          <div class="form-errors mt-2">
            <small class="text-danger" *ngIf="forgotForm.get('username').errors?.pattern">Email address is invalid.</small>
          </div>
          <div class="d-grid mb-3 mt-4">
            <button [disabled]="!forgotForm.valid" class="btn btn-primary btn-block">Submit</button>
          </div>
        </form>
        <div class="d-flex justify-content-center mb-3">
          <small>Already have an account?
            <a routerLink="/login">
              <small>Login Here</small>
            </a>
          </small>
        </div>
      </div>
    </div>
  </section>
</div>
