import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthguardService } from './services/authguard.service';
import { LoginComponent } from './AuthScreens/login/login.component';
import { SignupComponent } from './AuthScreens/signup/signup.component';
import { HomeComponent } from './MainScreens/home/home.component';
import { ForgotPasswordComponent } from './AuthScreens/forgot-password/forgot-password.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { SideNavComponent } from './components/SideNav/side-nav/side-nav.component';
import { ProfileComponent } from './MainScreens/profile/profile.component';
import { MedicationsComponent } from './MainScreens/medications/medications.component';
import { LabsComponent } from './MainScreens/labs/labs.component';
import { CarePlanComponent } from './MainScreens/care-plan/care-plan.component';
import { PriorVisitComponent } from './MainScreens/prior-visit/prior-visit.component';
import { MyNetworkComponent } from './MainScreens/my-network/my-network.component';
import { HelpComponent } from './MainScreens/help/help.component';
import { AboutComponent } from './MainScreens/about/about.component';
import { TermsComponent } from './MainScreens/terms/terms.component';
import { HeaderComponent } from './components/header/header.component';
import { EditProfileComponent } from './MainScreens/edit-profile/edit-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisitFormComponent } from './MainScreens/visit-form/visit-form.component';
import { NotesModalComponent } from './components/notes-modal/notes-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from './services/token.interceptor';
import { DoctorsListComponent } from './MainScreens/doctors-list/doctors-list.component';
import { BookAppointmentComponent } from './MainScreens/book-appointment/book-appointment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { SlotPatmentComponent } from './MainScreens/slot-patment/slot-patment.component';
import { environment } from 'src/environments/environment';
import { MeetingRoomComponent } from './MainScreens/meeting-room/meeting-room.component';
import { CameraComponent } from './components/MeetingComponents/camera/camera.component';
import { SettingsComponent } from './components/MeetingComponents/settings/settings.component';
import { DeviceSelectComponent } from './components/MeetingComponents/device-select/device-select.component';
import { ParticipantsComponent } from './components/MeetingComponents/participants/participants.component';
import { ImgDirective } from './components/img.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    ForgotPasswordComponent,
    SideNavComponent,
    ProfileComponent,
    MedicationsComponent,
    LabsComponent,
    CarePlanComponent,
    PriorVisitComponent,
    MyNetworkComponent,
    HelpComponent,
    AboutComponent,
    TermsComponent,
    HeaderComponent,
    EditProfileComponent,
    VisitFormComponent,
    NotesModalComponent,
    DoctorsListComponent,
    BookAppointmentComponent,
    SlotPatmentComponent,
    CameraComponent,
    SettingsComponent,
    DeviceSelectComponent,
    ParticipantsComponent,
    MeetingRoomComponent,
    ImgDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgxOtpInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(environment.STRIPE_PUBLISH_KEY),
  ],
  providers: [
    AuthguardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
