
<div class="d-flex flex-row justify-content-center align-items-center h-100-vh">
  <section class="auth-box">
    <div class="row">
      <div class="col-md-6 auth-img-section text-center justify-content-center align-items-center flex-wrap d-flex  flex-column" style="background-image: url('assets/img/login_bg.png');">
        <div class="auth-logo">
          <img src="assets/img/logo.svg" />
        </div>
        <h2>WELCOME</h2>
        <p>Sign in to get full access</p>
      </div>
      <div class="col-md-6 p-5">
        <h5>SIGN IN</h5>
        <!-- <div class="d-flex login-options mb-3">
          <div class="form-check me-5">
            <input class="form-check-input" type="radio" name="login_options" [(ngModel)]="loginOption" id="Doctor" value="1">
            <label class="form-check-label" for="Doctor">
              Doctor
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="login_options" [(ngModel)]="loginOption" id="Patient" value="2" checked>
            <label class="form-check-label" for="Patient">
              Patient
            </label>
          </div>
        </div> -->
        <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
          <div class="d-grid mb-3">
            <label class="mb-2">Email</label>
            <input type="email" class="form-control" formControlName="username" placeholder="Please enter email" name="username" />
          </div>
          <div class="d-grid mb-3">
            <label class="mb-2">Password</label>
            <input type="password" minlength="8" class="form-control" formControlName="password" placeholder="Please enter password" name="password" />
          </div>
          <div class="form-errors">
            <small class="text-danger" *ngIf="loginForm.get('username').errors?.pattern">Email address is invalid.</small>
            <small class="text-danger" *ngIf="loginForm.get('password').errors?.minlength">Password must be minimum 8 character long.</small>
          </div>
          <div class="d-flex justify-content-end mb-3">
            <small>
              <a routerLink="/forgot-password">
                <small>Forgot Password?</small>
              </a>
            </small>
          </div>
          <div class="d-grid mb-3">
            <button [disabled]="!loginForm.valid" class="btn btn-primary btn-block">
              <span *ngIf="processing">Please wait. Its processing...</span>
              <span *ngIf="!processing">LOGIN</span>
            </button>
          </div>
        </form>
        <div class="d-flex justify-content-center">
          <a routerLink="/register" class="label">New Users? Sign Up</a>
        </div>
      </div>
    </div>
  </section>
</div>
