<div class="container">
  <app-header title="Doctors"></app-header>
  <section class="doctor-wrapper">
    <div class="row gx-4">
      <div class="col-3 doctor-box mb-4" *ngFor="let doctor of doctorList">
        <a [routerLink]="['/book-appointment', doctor.id]">
          <div class="shadow-box sm-radius">
            <div class="row">
              <div class="user-img col-auto pe-1">
                <img class="radius-img" appImg default="assets/img/doctor.jpg" src="{{staticPath}}/{{doctor.profile_image}}" />
              </div>
              <div class="col d-grid">
                <h6 class="bordered-title pb-1 mb-2">{{doctor.name}}&nbsp;-&nbsp;<span>{{doctor.gender}}</span></h6>
                <small>{{doctor.address}}</small>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
</div>
