<div class="">
  <app-header title="Payment Form" subTitle="Please fill card details to pay appointment fee"></app-header>
  <div class="stripe-wrapper">
    <div class="col">
      <form [formGroup]="paymentForm" class="payment-form" (ngSubmit)="buy()">
        <div class="form-group mb-4">
          <input type="text" class="form-control" formControlName="name" placeholder="Full Name*" required />
          <span class="text-danger" *ngIf="paymentForm.get('name').hasError('required') && submitted">Full Name is required</span>
        </div>
        <div class="form-group mb-4">
          <div id="card-element" class="field"></div>
        </div>
        <button class="btn btn-success" [disabled]="submitted">
          <span *ngIf="!submitted">Pay for Appointment</span>
          <span *ngIf="submitted">Processing...</span>
        </button>
      </form>
    </div>
  </div>
</div>
