import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './AuthScreens/login/login.component';
import { SignupComponent } from './AuthScreens/signup/signup.component';
import { ForgotPasswordComponent } from './AuthScreens/forgot-password/forgot-password.component';
import { HomeComponent } from './MainScreens/home/home.component';
import { ProfileComponent } from './MainScreens/profile/profile.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoggedinguardGuard } from './guards/loggedinguard.guard';
import { CarePlanComponent } from './MainScreens/care-plan/care-plan.component';
import { HelpComponent } from './MainScreens/help/help.component';
import { LabsComponent } from './MainScreens/labs/labs.component';
import { MedicationsComponent } from './MainScreens/medications/medications.component';
import { MyNetworkComponent } from './MainScreens/my-network/my-network.component';
import { PriorVisitComponent } from './MainScreens/prior-visit/prior-visit.component';
import { TermsComponent } from './MainScreens/terms/terms.component';
import { AboutComponent } from './MainScreens/about/about.component';
import { EditProfileComponent } from './MainScreens/edit-profile/edit-profile.component';
import { VisitFormComponent } from './MainScreens/visit-form/visit-form.component';
import { DoctorsListComponent } from './MainScreens/doctors-list/doctors-list.component';
import { BookAppointmentComponent } from './MainScreens/book-appointment/book-appointment.component';
import { SlotPatmentComponent } from './MainScreens/slot-patment/slot-patment.component';
import { MeetingRoomComponent } from './MainScreens/meeting-room/meeting-room.component';

const routes: Routes = [
  {path: '', redirectTo:'/login', pathMatch:'full'},
  {path: 'login', component: LoginComponent, canActivate: [LoggedinguardGuard]},
  {path: 'register', component: SignupComponent, canActivate: [LoggedinguardGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoggedinguardGuard]},
  {path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard]},
  {path: 'care-plan', component: CarePlanComponent, canActivate: [AuthenticationGuard]},
  {path: 'help', component: HelpComponent, canActivate: [AuthenticationGuard]},
  {path: 'labs', component: LabsComponent, canActivate: [AuthenticationGuard]},
  {path: 'medications', component: MedicationsComponent, canActivate: [AuthenticationGuard]},
  {path: 'my-network', component: MyNetworkComponent, canActivate: [AuthenticationGuard]},
  {path: 'prior-visit', component: PriorVisitComponent, canActivate: [AuthenticationGuard]},
  {path: 'prior-visit/edit/:id', component: VisitFormComponent, canActivate: [AuthenticationGuard]},
  {path: 'terms', component: TermsComponent, canActivate: [AuthenticationGuard]},
  {path: 'about', component: AboutComponent, canActivate: [AuthenticationGuard]},
  {path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthenticationGuard]},
  {path: 'doctors', component: DoctorsListComponent, canActivate: [AuthenticationGuard]},
  {path: 'book-appointment/:id', component: BookAppointmentComponent, canActivate: [AuthenticationGuard]},
  {path: 'book-payment', component: SlotPatmentComponent, canActivate: [AuthenticationGuard]},
  {path: 'meeting-room/:slotid', component: MeetingRoomComponent, canActivate: [AuthenticationGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
