<!-- side bar main wrapper -->
<div class="side-nav-bar" [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }" *ngIf="isLoggedIn$ | async" [ngStyle]="getSideNavBarStyle((showSideNav | async))">

  <!-- overlay -->
  <!-- <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }"
        (click)="onSidebarClose()"></div> -->

  <!-- side bar-->
  <div class="side-nav-bar-menu-container">

    <!-- close button -->
    <!-- <div><span class="material-icons side-nav-bar-close" (click)="onSidebarClose()">clear</span></div> -->

    <!-- side bar content -->
    <div class="side-nav-bar-content-container">
      <div class="d-flex flex-column flex-shrink-0 text-white bg-white">
        <div class="logo center d-flex p-3 justify-content-center">
          <img src="assets/img/logo.svg" />
        </div>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <a routerLink="/home" class="nav-link" routerLinkActive="active" aria-current="page">
              <svg-icon src="assets/img/ic_dashboard.svg">
              </svg-icon>
              Dashboard
            </a>
          </li>
          <li>
            <a routerLink="/profile" routerLinkActive="active" class="nav-link">
              <svg-icon src="assets/img/ic_profile.svg">
              </svg-icon>
              Profile
            </a>
          </li>
          <li>
            <a routerLink="/medications" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_medications.svg">
              </svg-icon>
              Medications
            </a>
          </li>
          <li>
            <a routerLink="/labs" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_labs.svg">
              </svg-icon>
              Labs
            </a>
          </li>
          <!-- <li>
            <a routerLink="/care-plan" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_care_plan.svg">
              </svg-icon>
              My Care Plan
            </a>
          </li> -->
          <li>
            <a routerLink="/prior-visit" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_prior_visits.svg">
              </svg-icon>
              Prior Visits
            </a>
          </li>
          <li>
            <a routerLink="/my-network" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_network.svg">
              </svg-icon>
              My Network
            </a>
          </li>
          <li>
            <a routerLink="/help" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_help.svg">
              </svg-icon>
              Help
            </a>
          </li>
          <li>
            <a routerLink="/about" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_about.svg">
              </svg-icon>
              About
            </a>
          </li>
          <li>
            <a routerLink="/terms" class="nav-link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_trems.svg">
              </svg-icon>
              Terms
            </a>
          </li>
          <li>
            <a (click)="logout()" class="nav-link link" routerLinkActive="active">
              <svg-icon src="assets/img/ic_logout.svg">
              </svg-icon>
              Log Out
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
<ng-content></ng-content>
