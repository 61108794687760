import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/config/app.constants';
import { RestApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/Utils/helpers/common.service';
import {AuthguardService} from '../../services/authguard.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: any={};
  profile_img: String;
  problemList: any = [];
  medicalHistory: any = [];
  smokeHistory: any = [];
  medications: any = [];
  alergies: any = [];
  patientDetails: any = {};
  upcomingSlots: any=[];
  constructor(private authService: AuthguardService, private commonService: CommonService, private apiServices: RestApiService) { }

  ngOnInit(): void {
    this.getBookings();
    this.getProfile();
  }
  getBookings() {
    this.apiServices.getBookings().subscribe((res: any)=> {
        const {data} = res;
        this.upcomingSlots = data;
    });
  }
  getProfile() {
    this.apiServices.getUserProfile().subscribe((profile: any) => {
      if(profile.success){
        const {PatientDetail, ...user} = profile.data;
        this.user = user;
        this.patientDetails = PatientDetail;
        if(user.profile_image) this.profile_img = `${AppConstants.staticPath}/${user.profile_image}`;
        this.authService.updateUser(user);
        this.problemList = this.commonService.convertStringToList(PatientDetail.exist_medical_condition);
        this.medicalHistory = this.commonService.convertStringToList(PatientDetail.medical_condition);
        this.medications = this.commonService.convertStringToList(PatientDetail.current_medicine);
        this.alergies = this.commonService.convertStringToList(PatientDetail.allergies);
      }
    });
  }

}
