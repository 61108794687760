<div class="setting-container d-flex align-items-center">
  <div [style.display]="activeRoom ? 'none' : 'flex'">
    <div class="form-group" *ngIf="hasAudioInputOptions">
      <app-device-select [kind]="'audioinput'" [label]="'Audio Input Source'" [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasAudioOutputOptions">
      <app-device-select [kind]="'audiooutput'" [label]="'Audio Output Source'" [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasVideoInputOptions">
      <app-device-select [kind]="'videoinput'" #videoSelect [label]="'Video Input Source'" [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
  </div>
  <ng-container>
    <a class="mute ms-4" id="muteAudio" (click)="onMuteAudio()">
      <img *ngIf="!mutedAudio else mutedAudioIcon" src="assets/img/mic.png" />
      <ng-template #mutedAudioIcon>
        <img src="assets/img/mute-mic.png" />
      </ng-template>
    </a>
    <a class="mute" id="muteVideo" (click)="onMuteVideo()">
      <img *ngIf="!mutedVideo else mutedVideoIcon" src="assets/img/video.png" />
      <ng-template #mutedVideoIcon>
        <img src="assets/img/mute-video.png" />
      </ng-template>
    </a>
  </ng-container>
  <div class="setting-preview" [style.display]="isPreviewing ? 'block' : 'none'">
    <app-camera #camera type="self"></app-camera>
  </div>
</div>
