<div class="form-group d-flex">
  <label for="{{ id }}" class="form-label">{{ label }}</label>
<select class="custom-select form-control" id="{{ id }}"
        (change)="onSettingsChanged($event.target.value)">
    <option *ngFor="let device of devices"
            [value]="device.deviceId" [selected]="device.deviceId === selectedId">
        {{ device.label }}
    </option>
</select>
</div>
