<div class="container">
  <app-header title="Edit Profile"></app-header>
  <div class="edit-profile-wrapper">
    <div class="shadow-box">
      <div class="d-flex">
        <button class="btn btn-sm btn-outline-primary" routerLink="/profile">BACK</button>
      </div>

      <form [formGroup]="profileForm" (ngSubmit)="updateUser()">
        <div class="d-flex justify-content-center">
          <div class="img-upload">
            <img appImg src="{{filePath}}" />
            <input type="file" id="userImg" (change)="imagePreview($event)" />
            <span>Browse</span>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <p class="green-txt">Other Information</p>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Name</label>
                  <input type="text" class="form-control" formControlName="name" placeholder="Please enter name" name="name" />
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Email</label>
                  <input type="email" class="form-control" formControlName="email" placeholder="Please enter email" name="email" readonly />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Phone Number</label>
                  <input type="text" class="form-control" formControlName="phone" placeholder="Please enter phone number" name="phone" readonly />
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Date of birth</label>
                  <input type="date" formControlName="dob" class="form-control" placeholder="Enter your date of birth" name="dob" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Address</label>
                  <input type="text" class="form-control" formControlName="address" placeholder="Please enter your address" name="address" />
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Zipcode</label>
                  <input type="text" class="form-control" formControlName="zipcode" placeholder="Please enter your zipcode" name="zipcode" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Medical Conditions</label>
                  <textarea class="form-control" rows="3" formControlName="medical_condition" placeholder="Type here..."></textarea>
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Medical Details</label>
                  <textarea class="form-control" rows="3" formControlName="medical_details" placeholder="Type here..."></textarea>
                </div>
              </div>
            </div>
            <div class="d-grid gap-2 col-6 mt-2 mb-4 pe-3">
              <button class="btn btn-primary btn-block">SAVE</button>
            </div>
          </div>
          <div class="col">
            <p class="green-txt">Past Medical History</p>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">Please list your existing medical conditions?</label>
                  <textarea class="form-control" rows="3" formControlName="exist_medical_condition" placeholder="Type here..."></textarea>
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">What medications are you currently on?</label>
                  <textarea class="form-control" formControlName="current_medicine" rows="3" placeholder="Type here..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">What are your allergies?</label>
                  <textarea class="form-control" rows="3" formControlName="allergies" placeholder="Type here..."></textarea>
                </div>
              </div>
              <div class="col">
                <div class="d-grid mb-2">
                  <label class="form-label mb-1">What is your family history?</label>
                  <textarea class="form-control" rows="3" formControlName="family_history" placeholder="Type here..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-grid login-options mb-2">
                  <label class="form-label">Do you smoke or use tobacco?</label>
                  <div class="form-check me-5">
                    <input class="form-check-input" type="radio" formControlName="use_tobacco" id="use_tobacco_yes" value="1">
                    <label class="form-check-label" for="use_tobacco_yes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="use_tobacco" id="use_tobacco_no" value="0">
                    <label class="form-check-label" for="use_tobacco_no">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="d-grid login-options mb-2">
                  <label class="mb-2 form-label">Do you consume alcohol?</label>
                  <div class="form-check me-5">
                    <input class="form-check-input" type="radio" formControlName="use_alcohal" id="use_alcohal_yes" value="1">
                    <label class="form-check-label" for="use_alcohal_yes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="use_alcohal" id="use_alcohal_no" value="0">
                    <label class="form-check-label" for="use_alcohal_no">
                      No
                    </label>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </form>

    </div>
  </div>

</div>
