<div id="participantList">
  <div id="alone" class="d-flex flex-column align-items-center p-5 justify-content-center" [ngClass]="{ 'table': isAlone, 'd-none': !isAlone }">
    <p> You're the only one in this room. </p>
    <p>As others join, they'll start showing up here...</p>
    <div [ngClass]="{ 'd-none': isAlone }">
      <button type="button" class="btn btn-danger leave-room" title="Click to leave this room." (click)="onLeaveRoom()">
        Leave "{{ activeRoomName }}" Room?
      </button>
    </div>
  </div>
  <div id="mainUser" #list></div>
</div>
