<div class="container">
  <app-header title="My Care Plan"></app-header>
  <div class="care-plan-wrapper">
    <div class="shadow-box noxpad">
      <p class="green-txt d-flex justify-content-center pt-1 mb-4">Treatment Plan</p>
      <ul class="nav nav-pills  nav-justified justify-content-center mb-4">
        <div class="d-flex custom-pills">
          <li class="nav-item">
            <a class="nav-link link" [ngClass]="{'active':activeTab==1}" (click)="activeTab=1">Plan Outline</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link" [ngClass]="{'active':activeTab==2}" (click)="activeTab=2">Day Plan</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link" [ngClass]="{'active':activeTab==3}" (click)="activeTab=3">Calendar</a>
          </li>
        </div>
      </ul>


      <div class="tab-content clearfix">
        <div class="tab-pane" id="planOutline" [ngClass]="{'active':activeTab==1}">
          <div class="blue-bg py-3 px-3">
            <div class="row text-center">
              <div class="col text-start col-auto">
                <div *ngIf="user && user.profile_image!=''">
                  <img class="size_1_img radius-img" src="{{profile_img}}" />
                </div>
                <img class="size_1_img radius-img" *ngIf="user && (user.profile_image==null || user.profile_image=='')" src="assets/img/user-avatar.png" />
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h6>Today's Concerns</h6>
                    <h4 class="">August 2, 2021</h4>
                  </div>
                  <div class="col">
                    <h6>Acute Conditions</h6>
                    <h4>Pnemonia Kidney Injury</h4>
                  </div>
                  <div class="col">
                    <h6>Duration of stay</h6>
                    <h4>5 Days</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- User Details -->
            <div class="d-flex user-details mt-3 mb-1">
              <span>{{user.name}}</span>
              <span>Age: {{user.age}}</span>
              <!-- <span>Height: 5'7"</span>
              <span>Weight: 150lbs</span>
              <span>BMI: 22</span> -->
              <span>Patient ID: WXA{{user.id}}</span>
            </div>
          </div>

          <div class="row panel px-4 gx-4 pt-2">
            <div class="col">
              <div class="panel-heading">Problem</div>
              <li>AKI</li>
            </div>
            <div class="col">
              <div class="panel-heading">Short Term Goals</div>
              <li>Take Lisinopril</li>
              <li>Measure blood pressure</li>
            </div>
            <div class="col">
              <div class="panel-heading">Long Term Goals</div>
              <li>Ensure that diet is low sodium</li>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="second" [ngClass]="{'active':activeTab==2}">
          <div class="row">
            <div class="col-sm-12">
              <p>This is Day Plan</p>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="third" [ngClass]="{'active':activeTab==3}">
          <div class="row">
            <div class="col-sm-12">
              <p>This is Calendar</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
