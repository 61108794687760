import { Component, OnInit } from '@angular/core';
import {AuthguardService} from '../../services/authguard.service';
import { Router } from '@angular/router';
import{ AppConstants } from '../../config/app.constants';
import { RestApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    username: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });
  processing:boolean = false;

  constructor(private Authguardservice: AuthguardService, private router: Router, private apiService: RestApiService, private fb: FormBuilder, private toast: ToastrService) { }

  ngOnInit(): void {
  }
  loginSubmit() {
    const reqBody = {
      ...this.loginForm.value,
      user_type: AppConstants.USER_TYPES.PATIENT
    }
    this.processing = true;
    this.apiService.loginUser(reqBody).subscribe((res: any) => {
      this.processing = false;
      if(res && res.data && res.data['access_token']){
        const {access_token, ...user} = res.data;
        this.Authguardservice.setToken(access_token);
        this.Authguardservice.setUser(user);
        this.router.navigateByUrl("/home");
      } else if(res && res.message) {
        this.toast.warning(res.message);
      }
    },
    (err)=>{
      this.processing = false;
    });
  }

}
