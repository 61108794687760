import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImg]',
  host: {
    '(error)':'updateUrl()',
    '(load)': 'load()',
    '[src]':'src'
  }
})
export class ImgDirective {
  @Input() src: string;
  @Input() default:string = 'assets/img/user-avatar.png';
  @HostBinding('class') className;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
  updateUrl() {
    this.src = this.default;
  }
  load(){
    this.className = 'image-loaded';
  }
}
