import { Component, OnInit, ViewChild } from '@angular/core';
import {AuthguardService} from '../../services/authguard.service';
import { Router } from '@angular/router';
import { NgxOtpInputConfig, NgxOtpInputComponent } from 'ngx-otp-input';
import { FormBuilder, Validators } from '@angular/forms';
import { RestApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/Utils/helpers/common.service';
import { AppConstants } from 'src/app/config/app.constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  user='1';
  signupStep:number = 1;
  otpVerification:boolean = false;
  otpSent:boolean = false;
  activeVerify:boolean = false;
  processing:boolean = false;
  otp:Number;
  @ViewChild('ngxotp') ngxOtp: NgxOtpInputComponent;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    classList: {
      input: 'otp-field',
      inputFilled: 'otp-field-success',
    }
  };
  signupForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    address: ['', Validators.required],
    zipcode: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(8)]],
    profile_image: [''],
    medical_condition: ['', Validators.required],
    medical_details: ['', Validators.required],
    dob: ['', Validators.required],
    exist_medical_condition: ['', Validators.required],
    allergies: ['', Validators.required],
    current_medicine: ['', Validators.required],
    family_history: ['', Validators.required],
    use_tobacco: [0, Validators.required],
    gender: ['', Validators.required],
    use_alcohal: [0, Validators.required],
  });

  constructor(private Authguardservice: AuthguardService, private router: Router, private fb: FormBuilder, private apiServices: RestApiService, private commonService: CommonService, private toast: ToastrService ) { }

  ngOnInit(): void {
  }
  changeStep(step, back?){
    this.signupStep = back ? this.signupStep - 1 : this.signupStep + 1;
  }
  resetSignupStep() {
    this.signupStep = 1;
  }
  onFileChange($event) {
    this.signupForm.patchValue({
      profile_image: $event.target.files[0]
    })
  }
  signupSubmit() {
    const reqBody = this.signupForm.value;
    reqBody.user_type = AppConstants.USER_TYPES.PATIENT;
    let formData = new FormData();
    Object.keys(reqBody).forEach((x)=>{
      formData.append(x, reqBody[x]);
    });
    this.processing = true;
    this.apiServices.createUser(formData).subscribe((data: any) => {
      this.processing = false;
      if(data && data.success) {
        this.toast.success(data.message);
        this.otpVerification = true;
      }else if(data && data.success==false && data.message) {
        this.toast.error(data.message);
        this.resetSignupStep();
      }
      if(data && data['access_token']){
        // const {access_token, ...user} = data;
      }
    },
    (err)=>{
      this.processing = false;
    });
  }
  verifyOtpHandler() {
    const reqBody = {
      phone: this.signupForm.value.phone,
      otp: this.otp
    }
    this.apiServices.verifyOtp(reqBody).subscribe((data: any) => {
      if(data && data.success) {
        this.toast.success('Otp verified successfully. Please login to access panel.');
        this.router.navigate(['login']);
      }else{
        this.toast.error(data.message ? data.message : 'Some error occured');
      }
    });
  }
  sendOtp() {
    this.otpSent = true;
    this.ngxOtp.clear();
    this.activeVerify = false;
    setTimeout(()=>{
      this.otpSent = false;
    },120000);
  }
  activateVerify(otp) {
    this.otp = otp;
  }
  otpChange(fieldArr) {
    const errorExist = fieldArr.includes(null) || fieldArr.includes('');
    this.activeVerify = !errorExist;
  }
}
