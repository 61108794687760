<!-- Modal -->
<div class="modal fade custom-modal" [ngClass]="{'show': show}" [ngStyle]="{display: show ? 'block' : 'none'}" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
        <button type="button" class="btn-close btn-close-white" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="p-4 d-grid">
        <button type="button" class="btn btn-primary btn-block" (click)="submit()">SAVE</button>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{'show modal-backdrop fade': show}"></div>
