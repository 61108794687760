import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/config/app.constants';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  weatherUrl = AppConstants.WEATHER_API_KEY;
  constructor() { }
  getAge(dob) {
    const age = moment().diff(dob, 'years');
    return age;
  }
  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
  getTimeStops(start, end){
    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');

    if( endTime.isBefore(startTime) ){
      endTime.add(1, 'day');
    }

    var timeStops = [];

    while(startTime <= endTime){
      timeStops.push(moment(startTime).format('HH:mm'));
      startTime.add(30, 'minutes');
    }
    return timeStops;
  }
  getDatesArr(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }
  convertStringToList(str) {
    const strArr = str.split('\n');
    return strArr;
  }
}
