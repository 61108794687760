import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent implements OnInit {
  @Input() show: boolean = false;
  @Input() title: string = 'Heading';
  @Output() close = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
  submit() {
    this.close.emit('false');
  }
  closeModal() {
    this.close.emit('false');
  }
}
