import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../Utils/helpers/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {
  private loggedIn = new BehaviorSubject<boolean>(this.isUserExist());
  constructor(private router: Router, private commonService: CommonService) { }
  isLoggedIn(){
    return this.loggedIn.asObservable();
  }
  isUserExist() {
    return localStorage.getItem("SessionUser") ? true : false;
  }
  getToken(){
    return localStorage.getItem("token");
  }
  tokenExist() {
    return !!localStorage.getItem("token");
  }
  setToken(id){
    this.loggedIn.next(true);
    localStorage.setItem('token', id);
  }
  setUser(user){
    this.loggedIn.next(true);
    if(user && user.dob){
      user.age = this.commonService.getAge(user.dob);
    }
    localStorage.setItem('SessionUser', JSON.stringify(user));
  }
  updateUser(user){
    if(user && user.dob){
      user.age = this.commonService.getAge(user.dob);
    }
    localStorage.setItem('SessionUser', JSON.stringify(user));
  }
  async getUser() {
    const user = await localStorage.getItem("SessionUser");
    return JSON.parse(user);
  }
  async logoutUser(){
    this.loggedIn.next(false);
    localStorage.removeItem('SessionUser');
    localStorage.removeItem('token');
    this.router.navigateByUrl("/login");
  }
}
