
<app-side-nav
[direction]="'left'"
[navWidth]="225"
[duration]="0.5">
<div class="page-wrapper">
  <router-outlet></router-outlet>
</div>
</app-side-nav>

